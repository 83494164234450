














































































































import {Component, Prop, Vue} from 'vue-property-decorator'
import FileThumbnail from '@/components/FileThumbnail.vue'
import DropzoneOrChooseFile from '@/components/DropzoneOrChooseFile.vue'
// @ts-ignore
import {Cropper} from 'vue-advanced-cropper'
import {Modal} from '@simpli/vue-modal'

@Component({
  components: {DropzoneOrChooseFile, FileThumbnail, Cropper, Modal},
})
export default class FileManager extends Vue {
  @Prop({type: [Array, String], default: null}) value!: string[] | string | null
  @Prop({type: Array, default: []}) allowedFileTypes!: string[]
  @Prop({type: String, default: null}) exampleImage!: string
  @Prop({type: Boolean, default: false}) crop!: boolean
  @Prop({type: Boolean, default: true}) showFileName!: boolean
  @Prop({type: Number, default: null}) compressDimension!: number | null
  @Prop({type: Function, required: true}) getUploadUrl!: (
    extension: string
  ) => Promise<string>
  @Prop({type: Boolean, default: false}) useQueue!: boolean
  @Prop({type: Number, default: 0}) maxNumberOfImages!: number
  @Prop({type: String, required: true}) fileUrlPrefix!: string
  @Prop({type: Boolean, default: true}) convertToWebp!: string

  fileExtension: string | null = null
  imageUrlToCrop: string | null = null
  imageUrlCropped: string | null = null

  uploadQueue: ArrayBuffer[] = []

  get computedModel() {
    return this.value
  }

  set computedModel(val: string[] | string | null) {
    this.$emit('input', val)
  }

  get isEmpty() {
    return !this.value || !this.value.length
  }

  get fileTypesForPrompt() {
    return this.allowedFileTypes.join(',')
  }

  get isMultiFiles() {
    return this.value instanceof Array
  }

  get computedModelAsString() {
    if (this.isMultiFiles) {
      return this.computedModel?.[0]
    } else {
      return this.computedModel
    }
  }

  get computedModeAsArray() {
    if (this.isMultiFiles) {
      return this.computedModel as string[]
    } else {
      return [this.computedModel]
    }
  }

  get remainingSize() {
    return (
      this.maxNumberOfImages -
      (this.computedModeAsArray.length + this.uploadQueue.length)
    )
  }

  add(val: string) {
    if (this.isMultiFiles) {
      this.computedModel = [...(this.computedModel as string[]), val]
    } else {
      this.computedModel = val
    }
  }

  remove(index: number) {
    if (this.isMultiFiles) {
      const copy = (this.computedModel as string[]).slice()
      copy.splice(index, 1)
      this.computedModel = copy
    } else {
      this.computedModel = null
    }
  }

  removeQueue(index: number) {
    this.uploadQueue.splice(index, 1)
  }

  async processFiles(files: File[]) {
    if (this.maxNumberOfImages && files.length > this.remainingSize) {
      this.$toast.abort('components.fileManager.exceedMaxNumberOfImages')
    }

    const promises: Array<Promise<any>> = []
    for (const f of files) {
      promises.push(this.processFile(f))
    }
    await Promise.all(promises)
  }

  async processFile(file: File) {
    this.fileExtension = file.name.split('.').pop() ?? null

    if (!this.fileExtension || !this.isFileTypeAllowed(this.fileExtension)) {
      this.$toast.abort('components.fileManager.fileTypeNotAllowed')
      return
    }

    if (this.crop) {
      this.imageUrlToCrop = await this.$file.fileToUrl(file)
    } else {
      this.finishProcess(file)
    }
  }

  async finishProcess(fileBlob: Blob) {
    let imageBlob: Blob

    if (this.compressDimension) {
      const blob = await this.$file.compressFile(fileBlob, {
        maxWidth: this.compressDimension,
        maxHeight: this.compressDimension,
      })
      imageBlob = blob
    } else {
      imageBlob = fileBlob
    }

    let arrBuffer: ArrayBuffer | null

    if (this.convertToWebp) {
      const blobConverted = await this.$file.webpConverter(imageBlob)
      arrBuffer = await this.$file.blobToArrBuffer(blobConverted)
      this.fileExtension = 'webp'
    } else {
      arrBuffer = await this.$file.blobToArrBuffer(imageBlob)
    }

    await this.uploadOrQueue(arrBuffer)
  }

  onCropChange(e: any) {
    if (e.canvas) {
      this.imageUrlCropped = e.canvas.toDataURL()
    }
  }

  async finishCrop() {
    this.imageUrlToCrop = null
    if (this.imageUrlCropped) {
      const blob = this.$file.urlToBlob(this.imageUrlCropped)
      this.finishProcess(blob)
    }
  }

  isFileTypeAllowed(fileType: string) {
    return this.allowedFileTypes.some(aft =>
      fileType.toLowerCase().endsWith(aft)
    )
  }

  async uploadOrQueue(arrBuffer: ArrayBuffer | null) {
    if (this.useQueue) {
      if (!arrBuffer) {
        this.$toast.abort('components.fileManager.unexpectedFileError')
        return
      }

      this.uploadQueue.push(arrBuffer)
    } else {
      await this.upload(arrBuffer)
    }
  }

  public async processQueue() {
    for (let i = 0; i < this.uploadQueue.length; i++) {
      await this.upload(this.uploadQueue[i])
      this.uploadQueue.splice(i, 1)
      i--
    }
  }

  async upload(arrBuffer: ArrayBuffer | null) {
    if (!this.fileExtension) {
      return
    }
    const uploadUrl = await this.getUploadUrl(this.fileExtension)

    if (!arrBuffer) {
      this.$toast.abort('components.fileManager.unexpectedFileError')
      return
    }

    try {
      const path = new URL(uploadUrl).pathname
      await this.$file.uploadToS3(uploadUrl, arrBuffer)
      this.add(`${this.fileUrlPrefix}${path}`)
    } catch (e) {
      this.$toast.abort('components.fileManager.unexpectedFileError')
    }
  }
}
