




































import {Component, Prop, Vue} from 'vue-property-decorator'
import FileManager from '@/components/FileManager.vue'
import {Request} from '@simpli/serialized-request'
import FileThumbnail from '@/components/FileThumbnail.vue'
@Component({
  components: {FileThumbnail, FileManager},
})
export default class FileManagerWithList extends Vue {
  @Prop({type: [Array, String], default: null}) value!: string[] | string | null
  @Prop({type: String, required: true}) folder!: string
  @Prop({type: Boolean, default: false}) crop!: boolean
  @Prop({type: Number, default: 3000}) compressDimension!: number

  showFileName = false
  fileUrlPrefix = process.env.VUE_APP_CDN_URL

  images: string[] = []

  get computedModel() {
    return this.value
  }

  set computedModel(val: string[] | string | null) {
    this.$emit('input', val)
  }

  get allowedFileTypes(): string[] {
    return this.$auth.global.uploadImageAllowedFiles
  }

  async mounted() {
    return await this.populateList()
  }

  async populateList() {
    this.images = (await Request.get(`/client/upload/${this.folder}`)
      .name('imageList')
      .asArrayOf(String)
      .getData()) as string[]
  }

  async getUploadUrl(extension: string) {
    return await Request.get(`/client/upload/`, {
      params: {folder: this.folder, extension},
    })
      .name('getUploadUrl')
      .asString()
      .getData()
  }

  async removeFromList(i: number) {
    await this.$dialog.remove()
    const fileName = this.images[i].split('/').pop()
    await Request.delete(`/client/upload/${this.folder}/${fileName}`)
      .name('imageList')
      .asNumber()
      .getData()

    await this.populateList()
  }
}
