


























import {Component, Prop, Vue} from 'vue-property-decorator'
// @ts-ignore
import FileIcons from 'file-icons-js'

@Component
export default class FileThumbnail extends Vue {
  @Prop({type: String, required: true}) url!: string
  @Prop({type: Boolean, default: true}) showFileName!: boolean
  @Prop({type: String, default: null}) imageClass!: string
  @Prop({type: Boolean, default: null}) isImage!: boolean | null

  get filename() {
    return this.url.split('/').pop()
  }

  get extension() {
    return this.filename
      ?.split('.')
      .pop()
      ?.toLowerCase()
  }

  get fileicon() {
    return FileIcons.getClassWithColor(this.filename)
  }

  get hasImageExtension() {
    return (
      this.extension &&
      this.$auth.global.uploadImageAllowedFiles.includes(this.extension)
    )
  }
}
