























import {Component, Prop, Vue} from 'vue-property-decorator'
import Dropzone from '@/components/Dropzone.vue'
import {$} from '@/facade'

@Component({
  components: {Dropzone},
})
export default class DropzoneOrChooseFile extends Vue {
  @Prop({type: String, default: null}) allowedFileTypes!: string | null
  @Prop({
    type: String,
    default: () => $.t('components.fileManager.dropFilesOr'),
  })
  message!: string

  get readableAllowedFileTypes() {
    return this.allowedFileTypes?.replace(/,/g, ', ')
  }

  async chooseFile() {
    const files = await this.$file.promptForMultipleFiles(this.allowedFileTypes)

    if (files) {
      this.$emit('file', files)
    }
  }
}
