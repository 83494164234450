










import {Component, Vue} from 'vue-property-decorator'

@Component
export default class Dropzone extends Vue {
  dragging = false

  mounted() {
    this.configDropzone()
  }

  configDropzone() {
    const dropZone = this.$refs.dropzone as HTMLDivElement

    if (!dropZone) {
      return
    }

    dropZone.addEventListener('dragover', e => {
      e.stopPropagation()
      e.preventDefault()
    })

    dropZone.addEventListener(
      'dragenter',
      e => {
        if (e.target === dropZone) {
          this.dragging = true
        }
      },
      false
    )

    dropZone.addEventListener(
      'dragleave',
      e => {
        if (e.target === dropZone) {
          this.dragging = false
        }
      },
      false
    )

    dropZone.addEventListener('drop', async e => {
      e.stopPropagation()
      e.preventDefault()

      if (e.dataTransfer && e.dataTransfer.files.length) {
        this.$emit('drop', e.dataTransfer.files)
      }
      this.dragging = false
    })
  }
}
